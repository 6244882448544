@font-face {
  /*Myriad Set Pro Text*/
  font-family: 'myriadText';
  src: url("./assets/fonts/myriadText.ttf");
}

@font-face {
  font-family: 'myriadThin';
  src: url("./assets/fonts/myriadThin.ttf");
}

@font-face {
  font-family: "MyriadSetProUltralight";
  src: url("./assets/fonts/MyriadSetProUltralight.ttf");
}

@font-face {
  font-family: "myriadBold";
  src: url("./assets/fonts/myriadBold.ttf");
}

@font-face {
  font-family: "museoSlab";
  src: url("./assets/fonts/museoSlab.otf");
}

@font-face {
  font-family: "agencyGothic";
  src: url("./assets/fonts/agencyGothic.otf");
}

@font-face {
    font-family: "mavenPro";
    src: url("./assets/fonts/MavenProVFBeta.ttf");
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


.App {
  min-height: 100vh;
  width: 100vw;
  color: white;
  font-family: myriadText, sans-serif;
    overflow: hidden;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover {
    color: #ECC36A;
}


.box {
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: grid;
    grid-template: 1fr / 1fr 3fr;
}

.buttonStyle {
    font-family: myriadThin, sans-serif;
    font-size: 0.8rem;
    background-color: white;
    color: #2b2727;
    padding: 0.2rem;
    width: 40%;
    border: none;
    margin: 0 3%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.buttonStyle:hover {
    transform: scale(1.1);
}

.buttonStyle:hover:after {
    opacity: 1;
}

button:hover {
    cursor: pointer;
}

/* --------------------------------------------------- DASHBOARD NAV ----------------------------------------------------*/

/*.dashboardNav {*/
    /*background-image: linear-gradient(-60deg, #ECC36A 50%, #282c34 50%);*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*position: fixed;*/
    /*top: 0;*/
    /*right: 0;*/
    /*left: 0;*/
    /*z-index: 1000;*/
    /*margin-bottom: 5rem;*/
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
/*}*/

.dashboardNav {
    background-image: linear-gradient(-60deg, #ECC36A 50%, #282c34 50%);
        display: grid;
        grid-template: 1fr / 1fr 5fr;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1000;
        margin-bottom: 5rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboardNavLogo {
    padding: 0.5rem 4rem;
}

.headingTitle {

}

.dashboardNavMenu {
    align-self: center;
    height: 5.9vh;
    padding: 0 2rem 0 0;
    background-image: linear-gradient(-60deg, #282c34 50%, #282c34 50%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dashboardNav ul {
    margin: 2rem 2rem;
    min-width: 100%;
    width: 100%;
    text-align: right;
}

.dashboardNav li {
    display: inline;
    margin: 1.4rem;
    font-size: 1rem;
    font-family: mavenPro, sans-serif;
}

.dashboardNav a {
    text-decoration: none;
    color: #fff;
}

.dashboardNav a:hover {
    text-decoration: none;
    color: #ECC36A;
}

.dashboardContent {
    padding: 2rem;
}

.active li{
    background-image: linear-gradient(-60deg, #282c34 50%, #ECC36A 50%);
    color: white;
    padding: 0.5rem;
}

.active li:hover {
    color: white;
}

.socialMediaContainer {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #282c34;
    height: 60vh;
    width: 3vw;
    padding: 2rem 0;
    display: grid;
    grid-template: 1fr 1fr 1fr 1fr / 1fr;
    grid-gap: 2rem;
}

#socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLogo {
    width: 1.3rem;
    height: 1.3rem;
}

.mobile{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    height: 100px;
    width: 100vw; /* Need a specific value to work */
}

.mobileLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.countDown {
    text-align: center;
    width: 100vw;
    font-family: mavenPro, sans-serif;
    font-size: 2rem;
    color: #282c34;
}

footer {
    width: 100vw;
    height: 30vh;
    background-color: #282c34;
    padding: 3rem 5rem;
}

footer p {
    color: #798D97;
    text-align: left;
}

footer a {
    color: #798D97;
    text-align: left;
}

footer a:hover {
    color: #ECC36A;
}



/* --------------------------------------------------- LANDING PAGE ----------------------------------------------------*/

.landingPage {
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.carouselContainer {
    height: 90vh;
    width: 100vw;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.slideShowContentContainer {
    height: 90vh;
    width: 100vw;
}

.carouselImage {
    background-color: #000000;
    object-fit: cover;
    height: 90vh;
    width: 100vw;
    margin: 0 auto;
}



/* --------------------------------------------------- LANDING PAGE CONTENT ----------------------------------------------------*/

.landingPageContent {
    height: auto;
}

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #282c34 50%, #ECC36A 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
}

.bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
}

.bg3 {
    animation-duration:5s;
}

.brandContent {
    background-color:rgba(255,255,255,10);
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;

    padding:5vmin;
    margin: 3rem 5rem;
    text-align:center;
}

.clientContent {
    background-color:rgba(255,255,255,10);
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;

    padding:5vmin;
    margin: 3rem 5rem;
    text-align:center;
}

.content {
    background-color:rgba(255,255,255,.8);
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;

    padding:10vmin;
    margin: 3rem 5rem;
    text-align:center;
}

@keyframes slide {
    0% {
        transform:translateX(-25%);
    }
    100% {
        transform:translateX(25%);
    }
}


/* ----------------------------------------------------- OUR PARTNERS -------------------------------------------------------*/

.ourPartners h1 {
    font-size: 2rem;
    font-family: myriadBold, sans-serif;
}

.partners {
    /*display: grid;*/
    /*grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;*/
    /*grid-auto-flow: column;*/
}

.partners img {
    height: 4rem;
    width: 5rem;
}

/* ----------------------------------------------------- OUR HAPPY CLIENTS -------------------------------------------------------*/

.happyClients {

}

.happyClients h1 {
    font-size: 2rem;
    font-family: myriadBold, sans-serif;

}

/*.clients {*/
    /*display: grid;*/
    /*grid-template: 1fr / 1fr 1fr 1fr;*/
    /*grid-auto-flow: column;*/
    /*grid-gap: 0.5rem;*/
    /*justify-items: center;*/
/*}*/

.clients img {
    height: 8rem;
    width: 16rem;
}





/* --------------------------------------------------- LANDING PAGE BODY ----------------------------------------------------*/

.landingPageBody {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr 1fr;
    grid-gap: 1.5rem;
}

.landingPageBody img {
    height: 5rem;
    width: 5rem;
}

.landingPageBody h2 {
    margin: 1rem;
}

#whyChooseUsTitle {
    color: black;
}

.landingPageBody p {
    color: #5A6971;
    font-size: 1rem;
}


/* ----------------------------------------------------- WHY CHOOSE US -------------------------------------------------------*/

.whyChooseUs h1 {
    font-size: 2rem;
    font-family: myriadBold, sans-serif;

}

.whyChooseUs h2 {
    color: #fff;
}

.whyChooseUs p {
    color: #798D97;
    font-size: 1rem;
}

.whyChooseUs .description {
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr;
    grid-auto-flow: row;
    padding: 2rem 0;
    background-color: #1E2A38;
}

.whyChooseUs .descriptionBox {
    display: grid;
    grid-template: 1fr / 1fr 2fr;

    margin: 0 auto;
    padding: 1rem 5rem;
    width: 80%;
    text-align: center;
}

.whyChooseUs .info {
    text-align: justify;
}

.whyChooseUs .imageBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.whyChooseUs .imageBox img {
    height: 4rem;
    width: 4rem;
    margin: 0 5rem 0 0;
}


/* --------------------------------------------------- ABOUT US ----------------------------------------------------*/

.aboutUs {
    height: 120vh;
    width: 100vw;
    overflow: scroll;
    color: #000;
    padding: 10rem 10rem 5rem 10rem;
    content: "";
    background: url(assets/logo_yellow.png) no-repeat center;
    background-size: 40% 65%;
}

.aboutUsGrid {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
}

.aboutUsGrid .box {
    opacity: 0.9;
}

.aboutUs h1, h2 {
    margin-top: 1rem;
    font-family: myriadBold, sans-serif;
}


.aboutUs .imageBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.aboutUs .imageBox img {
    height: 6rem;
    width: 6rem;
    margin: 0 5rem 0 0;
}

.aboutUsGrid h1 {
    color: #fff;
}

.aboutUsGrid p {
    padding-top: 0.5rem;
    color: #fff;
}

.vision {
    background-color: #282c34;
}

.mission {
    background-color: #798D97;
}

.coreValues {
    background-color: #3B8066;
}

.team {
    background-color: #E0693A;
}

/* --------------------------------------------------- SERVICES ----------------------------------------------------*/

.services {
    height: 120vh;
    width: 100vw;
    color: #000;
    overflow: scroll;
    padding: 10rem 10rem 5rem 10rem;
    content: "";
    background: url(assets/logo_yellow.png) no-repeat center;
    background-size: 40% 65%;
}

.services h1, h2, h3 {
    font-family: myriadBold, sans-serif;
    color: #fff;
}

.services p {
    color: #fff;
}

.services ul {
    margin: 0 2rem 2rem 2rem;
    color: #fff;
}

.servicesInfo {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    background-color: #61dafb;
}

.serviceDescription {
    background-color: #ECC36A;
    text-align: justify;
    padding: 3rem;
}

.servicesInfo .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
}

.servicesInfo .imageBox img {
    height: 95%;
    width: 100%;
    margin: 0 5rem 0 0;
}

.subServiceContainer {
    min-height: 62vh;
    width: 100vw;
    padding: 10rem 10rem 5rem 6rem;
    content: "";
    background: url(assets/logo_yellow.png) no-repeat center;
    background-size: 40% 70%;
    color: white;
}

.subServiceContainer h1 {
    font-family: myriadBold, sans-serif;
    color: white;
}

.subServiceContainer ul {
    margin: 0 2rem 2rem 2rem;
    color: #fff;
}

.subServiceContainer .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subServiceContainer .imageBox img {
    height: 6rem;
    width: 6rem;
    margin: 0 5rem 0 0;
}

/* --------------------------------------------------- TOOLS ----------------------------------------------------*/

.tools {
    overflow: scroll;
    color: #000;
    display: grid;
    grid-template: 1fr / 1fr;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
    margin: 1.5rem 0;
}

.tools .box {
    opacity: 0.9;
}

.tools h1, h2, h3 {
    font-family: myriadBold, sans-serif;
    color: #fff;
}

.tools p {
    color: #fff;
}

.tools ul {
    margin: 0 2rem 2rem 2rem;
    color: #fff;
}

.tools .imageBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tools .imageBox img {
    height: 6rem;
    width: 6rem;
    margin: 0 5rem 0 0;
}

.cctvSurveillanceSystems {
    background-color: #282c34;
}

.electricFence {
    background-color: green;
}

.accessControlSystems {
    background-color: #798D97;
}

.gateAutomation {
    background-color: lightcoral;
}

.intrusionAlarmSystems {
    background-color: #3B8066;
}

.audioVideoIntercomSystems {
    background-color: #E0693A;
}

.vehicleTrackingSystems {
    background-color: #784A7C;
}

.timeAttendance {
    background-color: #708961;
}

.fireDetection {
    background-color: #4E0A22;
}

.audioSystems {
    background-color: #AB6E8A;
}

.itInfrastructureAndNetworking {
    background-color: #3E4042;
}

.structuredCabling {
    background-color: #2C6ED2;
}


/* --------------------------------------------------- GALLERY ----------------------------------------------------*/


.galleryPage {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    padding: 10rem 10rem 5rem 10rem;
    overflow: scroll;
}

/* --------------------------------------------------- CONTACT ----------------------------------------------------*/

.contact {
    min-height: 100vh;
    width: 100vw;
    overflow: scroll;
    color: #000;
    padding: 10rem 10rem 5rem 10rem;
    content: "";

    background-size: 40% 70%;
}

.contactRequestContainer {
    background-color: #ECC36A;
    padding: 2rem;
}

.contactRequestContainer textarea{
    height: 15rem;
    width: 100%;
}

.locationMap {
    margin-top: 2rem;
    height: 60%;
    width: 100%;
}


/* --------------------------------------------------- ADMIN LOGIN PAGE ----------------------------------------------------*/
.adminLoginPage {
    height: 65vh;
    width: 100%;
    margin: 0 auto;
    padding: 5rem 0;
}

.adminLoginContainer {
    width: 100%;
    height: 100vh;
}

.adminHeader {
    display: flex;
    margin: 0 auto;
    width: 20%;
    min-height: 15vh;
    justify-content: center;
    align-items: center;
    font-family: museoSlab, sans-serif;
}

.adminHeader h2 {
    text-align: center;
    color: #1E2A38;
    font-family: agencyGothic, sans-serif;
    letter-spacing: 0.25rem;
}

.adminBtnContainer {
    display: flex;
    justify-content: center;
    margin: 2% auto;
    width: 80%;
}

.adminLoginContainer {
    width: 50%;
    margin: 0 auto;
}

.emailInputContainerStyle {
    margin: 2% 5% 0 5%;
    display: grid;
    grid-template: 1fr / 1fr 3.5fr;
}

.emailInputLabelStyle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: myriadText, sans-serif;
    font-size: 0.9rem;
    color: #fff;
}

.emailInputLabelStyle h3 {
    color: #1E2A38;
}

.emailInputIconStyle {
    height: 0.5em;
    width: 1.5em;
}

.emailInput_InputContainerStyle {
    grid-area: 1 / 2 / 2 / 9;
    padding: 2%;
}

.emailInput_InputStyle {
    width: 70%;
    height: 2.2rem;
    padding: 3%;
    font-size: 0.9em;
    border: 2px solid orange;
    font-family: 'myriadText', sans-serif;
    color: black;
}

.passwordInputContainerStyle {
    margin: 2% 5% 0 5%;
    display: grid;
    grid-template: 1fr / 1fr 3.5fr;
}

.passwordInputLabelStyle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: myriadText, sans-serif;
    font-size: 0.9rem;
}

.passwordInputLabelStyle h3 {
    color: #1E2A38;
}

.passwordInputIconStyle {
    height: 1.5em;
    width: 1.5em;
}

.passwordInput_InputContainerStyle {
    grid-area: 1 / 2 / 2 / 9;
    padding: 2%;
}

.passwordInput_InputStyle{
    width: 70%;
    height: 2.2rem;
    padding: 3%;
    font-size: 0.9em;
    border: 2px solid orange;
    font-family: 'myriadText', sans-serif;
    color: black;
}

.contact .inputContainerStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}

.contact .inputLabelContainerStyle {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
}

.contact .inputLabelStyle {
    justify-self: flex-end;
}

.contact .inputStyle {
    width: 30%;
    height: 1.5rem;
    padding: 1%;
    font-size: 0.9rem;
    border: 2px solid orange;
    font-family: 'myriadText', sans-serif;
    color: black;
}

.adminPanelContainer .inputLabelContainerStyle {
    padding-bottom: 1rem;
}

.adminPanelContainer .inputLabelStyle {
    font-family: myriadText, 'sans-serif';

}

.adminPanelHeader {
    display: grid;
    grid-template: 1fr / 20fr 1fr;
}

.adminPanelLogOutBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #903E63;
    min-height: 8vh;
}

.adminPanelLogOutBtn:hover {
    cursor: pointer;
}

.adminPanel {
    height: 100vh;
    width: 100%;
    margin: 3% 0 0 0;
}

.adminPanel h2 {
    font-family: agencyGothic, sans-serif;
    color: white;
    letter-spacing: 0.25rem;
    text-align: center;
}

.my-tablist {
    font-size: 1rem;
    color: white;
    height: 90vh;
}

.adminPageErrorContainer {
    width: 50%;
    margin: 0 auto;
    background-color: darkred;
    border-radius: 0.4rem;
}

.adminPageErrorContainer p {
    text-align: center;
    padding: 1%;
    font-size: 0.8rem;
    font-family: myriadThin, sans-serif;
    color: #fff;
}


/* -------------------------------------------- ADMIN PANEL ------------------------------------------------- */
.adminPanelContainer {
    min-height: 100vh;
    width: 100%;
    margin: 6% 0 0 0;
    padding: 0.01rem 3%;
}

.adminPanel {
    height: 100%;
    width: 100%;
    background-color: darkgrey;
}

.adminPanelHeader {
    display: grid;
    grid-template: 1fr / 20fr 1fr;
}

.adminPanelLogOutBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #903E63;
    min-height: 8vh;
}

.adminPanelLogOutBtn:hover {
    cursor: pointer;
}

.adminPanel h2 {
    font-family: agencyGothic, sans-serif;
    color: white;
    letter-spacing: 0.25rem;
    text-align: center;
}

.my-tablist {
    font-size: 1rem;
    color: white;
    height: 90vh;
}

.adminPageErrorContainer {
    width: 50%;
    margin: 0 auto;
    background-color: darkred;
    border-radius: 0.4rem;
}

.adminPageErrorContainer p {
    text-align: center;
    padding: 1%;
    font-size: 0.8rem;
    font-family: myriadThin, sans-serif;
    color: #fff;
}

.panelContainer {
    margin: 2%;
}

.panelDescription {
    padding: 1%;
    margin: 1%;
    background-color: #ECC36A;
}

.panelDescription textarea {
    padding: 2%;

}


/* -------------------------------------------------- HOME PANEL ------------------------------------------------- */

.homePanel {
    width: 60vw;
    height: auto;
}

.servicesOffered {
    margin: 2%;
}

hr {
    margin: 2%;
}

/* -------------------------------------------------- ABOUT US PANEL ------------------------------------------------- */

.aboutUsPanel {
    width: 60vw;
    height: auto;
    margin: 2rem 0;
}

hr {
    margin: 2%;
}

/* -------------------------------------------------- SERVICES PANEL ------------------------------------------------- */

.servicesPanel {
    width: 60vw;
    height: auto;
    margin: 2rem 0;
}


/* ------------------------------------------------ GALLERY PANEL ------------------------------------------------- */

.galleryPanel {
    margin: 0 auto;
    width: 60vw;
}

.galleryPanel .galleryBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% 0;
}

.galleryPageImagesContainer {
    margin: 5% auto;
}

.currentImages {
    background-color: #000;
    padding: 2% 0;
    height: auto;
}

.currentImagesContainer {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    grid-gap: 2%;
}

.currentImagesContainer .imagesContainer {
    overflow: scroll;
}

.imagesContainer h3 {
    color: white;
    text-align: center;
}

/* -------------------------------------------------- CONTACT US PANEL ------------------------------------------------- */

.contactUsPanel {
    width: 60vw;
    height: auto;
    margin: 2rem 0;
}

hr {
    margin: 2%;
}

.message {
    display: grid;
    grid-template: 1fr / 3fr 1fr;
    background-color: #708961;
    margin: 1rem 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.message .messageTitle {
    background-color: white;
}

.messageTitle h3 {
    padding: 0.5rem;
    color: #4D534D;
}

.message p {
    background-color: white;
    padding: 1rem;
    color: #000;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}