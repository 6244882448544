@font-face {
    /*Myriad Set Pro Text*/
    font-family: 'myriadText';
    src: url("./assets/fonts/myriadText.ttf");
}

@font-face {
    font-family: 'myriadThin';
    src: url("./assets/fonts/myriadThin.ttf");
}

@font-face {
    font-family: "MyriadSetProUltralight";
    src: url("./assets/fonts/MyriadSetProUltralight.ttf");
}

@font-face {
    font-family: "myriadBold";
    src: url("./assets/fonts/myriadBold.ttf");
}

@font-face {
    font-family: "museoSlab";
    src: url("./assets/fonts/museoSlab.otf");
}

@font-face {
    font-family: "agencyGothic";
    src: url("./assets/fonts/agencyGothic.otf");
}

@font-face {
    font-family: "mavenPro";
    src: url("./assets/fonts/MavenProVFBeta.ttf");
}

/**/


/* ----------------------------------------------------------- SIDE MENU ---------------------------------------------------------------*/

.mobileMenu {
    position: absolute;
    height: 100vh;
    width: 0;
    top: 0;
    left: 0;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 1.6rem;
    height: 1.2rem;
    right: 0.8rem;
    top: 1.9rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ECC36A;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* --------------------------------------------------------- MAIN APP ------------------------------------------------------*/

@media only screen and (min-width: 180px) and (max-width: 667px) {
    footer {
        width: 100vw;
        height: 26vh;
        background-color: #282c34;
        padding: 2rem 1rem;
    }

    footer p {
        font-size: 0.7rem;
        color: #798D97;
        text-align: left;
    }

    footer a {
        font-size: 0.7rem;
        color: #798D97;
        text-align: left;
    }

    footer a:hover {
        color: #ECC36A;
    }


    .dashboardNav {
        background-image: linear-gradient(-60deg, #ECC36A 60%, #282c34 50%);
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        margin-bottom: 5rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .dashboardNavLogo {
        padding: 0.5rem 2rem;
    }

    .dashboardNavMenu {
        align-self: center;
        height: 5.9vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .socialMediaContainer {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #282c34;
        height: 15vh;
        width: 8vw;
        padding: 1rem 0;
        display: grid;
        grid-template: 1fr 1fr / 1fr;
        /*grid-gap: 2rem;*/
    }

    #socialLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .socialLogo {
        width: 1rem;
        height: 1rem;
    }


    .dashboardContent {
        padding: 0;
    }

    /* --------------------------------------------------- LANDING PAGE -----------------------------------------------------*/

    .landingPage {
        padding-top: 4rem;
    }

    .carouselContainer {
        height: 65vh;
        width: 100vw;
        overflow: hidden;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .slideShowContentContainer {
        height: 70vh;
        width: 100vw;
    }

    .carouselImage {
        background-color: #000000;
        object-fit: cover;
        height: 70vh;
        width: 100vw;
        margin: 0 auto;
    }

    .brandContent {
        background-color:rgba(255,255,255,10);
        box-shadow:0 0 .25em rgba(0,0,0,.25);
        box-sizing:border-box;

        padding:5vmin;
        margin: 1rem 0.5rem;
        text-align:center;
    }

    .clientContent {
        background-color:rgba(255,255,255,10);
        box-shadow:0 0 .25em rgba(0,0,0,.25);
        box-sizing:border-box;

        padding: 5vmin;
        margin: 1rem 0.5rem;
        text-align:center;
    }

    .content {
        background-color:rgba(255,255,255,.8);
        box-shadow:0 0 .25em rgba(0,0,0,.25);
        box-sizing:border-box;

        padding: 5vmin 0.5vmin;
        margin: 1rem 0.5rem;
        text-align:center;
    }

    /* --------------------------------------------------- LANDING PAGE BODY ----------------------------------------------------*/

    .landingPageBody {
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr 1fr;
        grid-gap: 0.1rem;
    }

    .landingPageBody img {
        height: 3rem;
        width: 3rem;
    }

    .landingPageBody h2 {
        margin: 1rem;
        font-size: 0.68rem;
    }

    #whyChooseUsTitle {
        color: black;
    }

    .landingPageBody p {
        color: #5A6971;
        font-size: 1rem;
    }

    /* ----------------------------------------------------- WHY CHOOSE US -------------------------------------------------------*/

    .whyChooseUs h1 {
        font-size: 1.4rem;
        font-family: myriadBold, sans-serif;

    }

    .whyChooseUs p {
        color: #798D97;
        font-size: 0.9rem;
    }

    .whyChooseUs .description {
        display: grid;
        grid-template: 1fr 1fr 1fr / 1fr;
        grid-auto-flow: row;
        padding: 0.5rem 0;
        background-color: #1E2A38;
    }

    .whyChooseUs .descriptionBox {
        display: grid;
        grid-template: 1fr / 22% 2fr;

        margin: 0 auto;
        padding: 1rem;
        width: 100%;
        text-align: center;
    }

    .whyChooseUs .imageBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .whyChooseUs .imageBox img {
        height: 3rem;
        width: 3rem;
    }

    .whyChooseUs .info {
        text-align: justify;
    }

    .whyChooseUs .info h2 {
        font-size: 1rem;
    }

    /* --------------------------------------------------------- OUR PARTNERS ---------------------------------------------------------------- */

    .ourPartners h1 {
        font-size: 1.4rem;
        font-family: myriadBold, sans-serif;
        margin-bottom: 2rem;
    }

    .partners {
        /*display: grid;*/
        /*grid-template: 1fr 1fr 1fr/ 1fr 1fr 1fr;*/
        /*grid-gap: 1rem;*/
        /*grid-auto-flow: column;*/
    }

    .partners img {
        height: 4rem;
        width: 5rem;
    }

    /* ----------------------------------------------------- OUR HAPPY CLIENTS -------------------------------------------------------*/

    .happyClients {

    }

    .happyClients h1 {
        font-size: 1.4rem;
        font-family: myriadBold, sans-serif;
        margin-bottom: 2rem;
    }

    /*.clients {*/
        /*display: grid;*/
        /*grid-template: 1fr / 1fr 1fr 1fr;*/
        /*grid-auto-flow: column;*/
    /*}*/

    .clients img {
        height: 5rem;
        width: 10rem;
    }


    /* --------------------------------------------------- ABOUT US ----------------------------------------------------*/

    .aboutUs {
        height: 120vh;
        width: 100vw;
        color: #000;
        overflow: scroll;
        padding: 8rem 1rem 4rem 1rem;
        content: "";
        background: url(assets/logo_yellow.png) no-repeat center;
        background-size: 100% 50%;
    }

    .aboutUs h1, h2 {
        font-family: myriadBold, sans-serif;
        font-size: 1.4rem;
        text-align: center;
    }

    .aboutUsGrid {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
        grid-auto-flow: row;
        grid-gap: 0.5rem;
    }

    .aboutUsGrid .box {
        padding: 1rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: grid;
        grid-template: 22% 2fr / 1fr;
    }

    .aboutUs .imageBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .aboutUs .imageBox img {
        height: 3rem;
        width: 3rem;
        margin: 0;
    }

    .aboutUs p {
        margin-bottom: 1rem;
    }


    /* --------------------------------------------------- SERVICES ----------------------------------------------------*/

    .services {
        height: 120vh;
        width: 100vw;
        color: #000;
        overflow: scroll;
        padding: 5rem 1rem 4rem 1rem;
        content: "";
        background: url(assets/logo_yellow.png) no-repeat center;
        background-size: 100% 50%;
        /*overflow: scroll;*/
    }

    .services h1 {
        font-family: myriadBold, sans-serif;
        font-size: 1.4rem;
        text-align: center;
    }

    .services h2 {
        font-family: myriadBold, sans-serif;
        text-align: center;
    }

    .services ul {
        margin: 0 2rem 2rem 2rem;
    }

    .servicesInfo {
        display: grid;
        grid-template: 2fr 0.1fr / 1fr;
        background-color: #61dafb;
    }

    .serviceDescription {
        background-color: #ECC36A;
        text-align: justify;
        padding: 1rem;
    }

    .servicesInfo .imageBox {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .servicesInfo .imageBox img {
        height: 8.5rem;
        width: 10rem;
    }

    .subServiceContainer {
        min-height: 62vh;
        width: 100vw;
        padding: 7rem 1rem 1rem 1rem;
        content: "";
        background: url(assets/logo_yellow.png) no-repeat center;
        background-size: 50% 10%;
        color: white;
    }

    .subServiceContainer section {
        display: grid;
        grid-template: 0.1fr 2fr / 1fr;
        grid-auto-flow: row;
        grid-gap: 0.5rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .subServiceContainer h1 {
        font-family: myriadBold, sans-serif;
        color: white;
    }

    .subServiceContainer .imageBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .subServiceContainer .imageBox img {
        height: 3rem;
        width: 3rem;
        margin: 0 0 0.5rem 0;
    }

    .subServiceContainer p {
        margin-bottom: 1rem;
    }




    /* --------------------------------------------------- TOOLS ----------------------------------------------------*/

    .tools {
        overflow: scroll;
        color: #000;
        display: grid;
        grid-template: 1fr / 1fr;
        grid-auto-flow: row;
        grid-gap: 0.5rem;
        margin: 1.5rem 0;
    }

    .tools .box {
        padding: 1rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: grid;
        grid-template: 0.1fr 2fr / 1fr;
    }

    .tools h1 {
        font-family: myriadBold, sans-serif;
        font-size: 1.4rem;
        text-align: center;
    }

    .services h2 {
        font-family: myriadBold, sans-serif;
        text-align: center;
    }

    .tools p {
        color: #fff;
        margin-bottom: 1rem;
    }

    .tools ul {
        margin: 0 2rem 2rem 2rem;
        color: #fff;
    }

    .tools .imageBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 1rem;
    }

    .tools .imageBox img {
        height: 3rem;
        width: 3rem;
        margin: 0;
    }



    /* --------------------------------------------------- GALLERY ----------------------------------------------------*/

    .galleryPage {
        width: 100vw;
        min-height: 100vh;
        padding: 7rem 2rem 2rem 2rem;
    }

    /* --------------------------------------------------- CONTACT ----------------------------------------------------*/

    .contact {
        min-height: 50vh;
        width: 100vw;
        overflow: scroll;
        color: #000;
        padding: 7rem 1rem 2rem 1rem;
        content: "";

        background-size: 40% 70%;
    }


    .contact h1 {
        font-size: 1.4rem;
        text-align: center;
    }

    .contactRequestContainer {
        margin-top: 1rem;
        position: relative;
        background-color: #ECC36A;
        padding: 1rem;

    }

    .locationMap {
        margin-top: 1rem;
        height: 60%;
        width: 100%;
    }

    .contact .inputContainerStyle {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
    }

    .contact .inputLabelContainerStyle {
        display: flex;
        flex-direction: column;
        justify-content: baseline;
    }

    .contact .inputLabelStyle {
        justify-self: flex-end;
    }

    .contact .inputStyle {
        width: 100%;
        height: 1.9rem;
        padding: 1%;
        font-size: 0.9rem;
        border: 2px solid orange;
        font-family: 'myriadText', sans-serif;
        color: black;
    }

    .contactRequestContainer textarea{
        height: 10rem;
        width: 100%;
    }
}